import { FeatureData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../../components/View';
import Form from '../../../../../containers/Espace/Form';
import params from '../../../../../params/livraison.json';
import TemplateEspace from '../../../../../templates/espace';
import requireEspace, { EspaceProps } from '../../../../../utils/requireEspace';
import { UserProps } from '../../../../../utils/requireUser';

const PageLivraisonsCreate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId },
}) => (
  <TemplateEspace espace={espace} user={user}>
    <CMSView>
      <Form
        itemPathnamePrefix={`/espaces/${espaceId}/params/livraisons/`}
        model={
          new FeatureData({
            espaceId,
            params,
            collectionName: 'livraisons',
          })
        }
        name="livraison"
        type="create"
      />
    </CMSView>
  </TemplateEspace>
);

export default requireEspace(PageLivraisonsCreate);
